.App {
  text-align: center;
}

.App_Container {
  max-width: 420px;
  padding: 26px 34px 24px;
  margin: auto;
}

form {
  margin: 25px 0;
}

.Form__Col input,
.Form__Col textarea {
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;
  padding: 17.5px 20px;
  width: 100%;
}
button {
  cursor: pointer;
  background: #1652f0;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.00214085px;
  line-height: 23px;
  padding: 18px 31px 17px;
  text-align: center;
  margin-top: 22px;
  width: 100%;
}
button:disabled {
	opacity: .5;
	cursor: not-allowed;
}

.LoginSignupModal__Content hr {
  border: none;
  border-top: 2px solid #d8d8d8;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
}
.LoginSignupModal__Content hr:after {
  background: #fff;
  content: "OR";
  padding: 0 20px;
  position: relative;
  top: -12px;
  font-weight: 600;
}

.SendModal {
  max-width: 420px;
}

.SendModal__Token {
  margin: 24px 0;
}
.SendModal__Token img {
  height: 120px;
}

img {
  max-width: 100%;
}

.LogoutButton {
  background: rgba(0, 0, 0, 0.08);
  color: #000000;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.00187324px;
  line-height: 21px;
  padding: 6px 14px;
  text-align: center;
  width: 104px;
}

.LoginSignupModal__Error {
  color: red;
}

.ProfilePage__Text {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 54px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%;
}

.ProfilePage__CopyAddress {
  background: #000;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: inline;
  height: 33px;
  margin: 10px 0 11px;
  min-width: 81.38px;
  padding: 4px 10px;
  position: absolute;
  right: 5px;
  top: 0;
  width: unset;
}

.LogoutButton.reload {
  width: auto;
}

.AssetLoading__Image {
  position: relative;
  right: -25px;
}

.Options__Container {
  display: grid;
  max-width: 420px;
  grid-column-gap: 16px;
  -webkit-column-gap: 16px;
  column-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 16px;
  row-gap: 16px;
}

.Option {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  box-shadow: 0 3px 11px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  font-size: 14px;
  height: 100%;
  padding: 16px 18.9px 18px;
  position: relative;
  cursor: pointer;

  display: grid;
  place-items: center;
}

.Option img {
  max-width: 60px;
}

.Option.active {
  background: rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 450px) {
  .Options__Container {
    margin-left: 0;
    max-width: 100vw;
    margin-top: 25px;
    display: grid;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
    column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 16px;
    row-gap: 16px;
  }
}
